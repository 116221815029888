function Contact() {
  return (
    <div className="container contact row" style={{ paddingTop: '4rem' }}>
      <div>
        <h2 className="main-title">대표이사 약력</h2>
        <div className="card-cover">
          <div className="col-md-12">
            <div className="col">
              <div>
                <p className="inline-text">2023.6 ~ : (주)디오모빌리티 대표이사</p>
                <p className="inline-text">2022.5 ~ 2023.5 : 서울특별시버스운송사업조합 실장(총괄)</p>
                <p className="inline-text">2014.7 ~ 2022.4 : 서울특별시버스운송사업조합 사업기획실 실장</p>
                <p className="inline-text">2009.3 ~ 2014.6 : 서울특별시버스운송사업조합 정책기획실 실장</p>
                <p className="inline-text">2003.11 ~ 2009.2 : 서울특별시버스운송사업조합 교통정책연구원 원장</p>
                <p className="inline-text">2002.9 ~ 2003.10 : 서울연구원 대중교통개편지원연구단 초빙연구위원</p>
                <p className="inline-text">2002.3 ~ 2002.8 : (주)창신종합엔지니어링 차장</p>
                <p className="inline-text">2002.3 ~ 2002.8 : 영남대학교 도시공학과 강사</p>
                <p className="inline-text">1999.4 ~ 2002.2 : 일본국립나고야공업대학(NIT) 교수</p>
                <p className="inline-text">1996.10 ~ 1998.9 : 일본국립오사카대학 강의 및 연구조교</p>
                <p className="inline-text">
                  1996.4 ~ 1999.3 : 일본국립오사카대학 대학원 토목공학과 Ph.D.(대중교통계획 전공)
                </p>
                <p className="inline-text">1995.10 ~ 1996.3 : 일본국립오사카대학 연구원</p>
                <p className="inline-text">1995.3 ~ 1995.9 : (주)창신종합엔지니어링 연구원</p>
                <p className="inline-text">
                  1993.3 ~ 1995.2 : 영남대학교 대학원 도시공학과 공학석사(도시 및 교통계획 전공)
                </p>
                <p className="inline-text">1988.5 ~ 1990.8 : 해병대 만기 전역</p>
                <p className="inline-text">1986.3 ~ 1993.2 : 영남대학교 공과대학 도시공학과 공학사(도시공학 전공)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginLeft: '40px' }}>
        <h2 className="main-title">대표이사 활동</h2>
        <div className="card-cover">
          <div className="col-md-12">
            <div className="col">
              <div>
                <p className="inline-text">
                  2024.10 : 파주시 시내버스(좌석형) 한정면허 운송사업 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.9 : 안동시 대중교통(시내버스, 택시) 운수종사자 서비스 평가 용역 제안서 평가위원회 위원장
                </p>
                <p className="inline-text">
                  2024.9 : 2024년 하반기 경기도 시내버스 공공관리제 노선입찰형 운송사업 제안서 평가위원회 위원장
                </p>
                <p className="inline-text">
                  2024.9 : 2024년 제2차 파주시 노선입찰형 마을버스(한정면허) 운송사업자 선정 제안서 평가위원회 위원
                </p>
                <p className="inline-text">2024.9 ~ 2026.9 : 안성시 택시정책위원회 위원</p>
                <p className="inline-text">
                  2024.8 : 부산광역시 빅데이터 기반 시내버스운행관리 분석시스템 개발 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.8 : 2024년 울산광역시 일반택시 경영 및 서비스 평가 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.7 : 2024년 대전광역시 시내버스 디지털 노선안내도 구축 사업 제안서 평가위원회 위원장
                </p>
                <p className="inline-text">
                  2024.7 : 경기교통공사 버스 운송비용 정산시스템 유지관리 사업 제안서 평가위원회 위원장
                </p>
                <p className="inline-text">
                  2024.6 : 익산시 자율주행 스마트플랫폼 구축 기본설계 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.5 : 대구광역시 도로건설·관리계획(’26~’30) 수립 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.5 : 2024년 충남 노선버스 운송손익 분석 및 공정한 배분에 관한 연구용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.5 : 2024년 경기도 공공버스 운수종사자 교육 및 조사 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.5 : 2024년 하남시 도시형교통모델 운송사업자 모집 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.4 : 괴산군 농어촌버스 노선개편 및 운영 효율화 방안 연구용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.4 : 광명권 광역도시철도망 기본구상 및 사전타당성조사 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.4 : 2023년 제2차 경기도 공공버스 운송사업 노선입찰 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.3 : 군위군 관내 간선도로망 구축을 위한 연구용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.3 : 2024 경기도 시내버스업체 경영 및 서비스평가 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">2024.3 : 대구형 DRT 운영 타당성 용역 제안서 평가위원회 위원</p>
                <p className="inline-text">
                  2024.3 : 나주시 시내버스 업체 회계감사 및 표준운송원가 산정 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">2024.3 : 광주시 똑버스 운송사업 제안서 평가위원회 위원</p>
                <p className="inline-text">
                  2024.3 : 하빈 동곡~감문간 도로건설 사업구간 연장 타당성 검토 용역 제안서 평가위원회 위원
                </p>
                <p className="inline-text">2024.3 : 고양시 똑버스 운송사업 제안서 평가위원회 위원</p>
                <p className="inline-text">
                  2024.2 ~ 2025.12 : 경기교통공사 노선입찰형 준공영제 사업 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2024.1 : 서울특별시 관악구 마을버스 운송사업자 선정 제안서 평가위원회 위원
                </p>
                <p className="inline-text">
                  2023.11 : 2023년 하남시 도시형교통모델 운송사업자 모집 제안서 평가위원회 위원
                </p>
                <p className="inline-text">2023.6 : 구미시 시내버스 노선개편 용역 제안서 평가위원회 위원 및 자문위원</p>
                <p className="inline-text">2023.6 ~ 2025.6 : 춘천시 노선버스 준공영제 관리위원회 부위원장</p>
                <p className="inline-text">2008.4 ~ 2012.3 : 서울특별시 교통약자이동편의증진위원회 위원</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contact;
